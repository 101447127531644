import { Outlet, useNavigate } from 'react-router-dom';

const Layout = () => {
    const navigate = useNavigate()

    const handleGoHome = () => {
        navigate('/')
    }

    return (
        <>
            <div className="flex flex-col h-screen">

                <header className="flex border-b-[1px] border-gray-200">
                    <div id='header-wrapper' className='flex flex-row px-6 md:px-0 py-4 max-w-screen-lg mx-auto w-full justify-between'>
                        <div onClick={handleGoHome} className='flex flex-row gap-x-2 items-center font-medium text-xl text-black cursor-pointer'>
                            <span style={{ fontSize: '35px', color: 'blue' }}>👽</span>
                            <p className='text-2xl font-medium'>Z-Booster</p>
                        </div>
                        <div className='flex flex-row gap-x-5 items-center font-medium cursor-pointer'>
                            <p><a href='#contact'>Contact Us</a></p>
                            {/* <p><a href='/faq'>FAQ</a></p> */}
                        </div>
                    </div>
                </header>

                <div className="flex flex-col px-6 md:px-0 flex-grow max-w-screen-lg mx-auto w-full items-center">
                    <Outlet />
                </div>

                <div className='flex border-t-[1px] border-gray-200'>
                    <div id='booter-wrapper' className='flex flex-col md:flex-row justify-between max-w-screen-lg mx-auto w-full font-normal text-sm px-6 md:px-0 py-7'>
                        <div>
                            Copyright&nbsp;©&nbsp;2023 Z-Booster Ltd.
                        </div>
                        <div id='contact' className='flex flex-row gap-x-2'>
                            <a href='/terms'>Terms</a> | <a href='/privacy'>Privacy</a> | <span style={{fontSize: '30px'}}></span> ✉️ <a href='mailto:support@z-booster.com' className='text-red-600 font-bold underline'>support@z-booster.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Layout;